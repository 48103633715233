import React, { Suspense, lazy } from "react";
import logo from "./logo.svg";
import "./App.css";
import ReactLoading from "react-loading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/layouts/Layout";
import { StudentParents } from "./pages/StudentParents";
import { Foundations } from "./pages/Foundations";
import { YearCollege } from "./pages/YearCollege";
import ScrollToTop from './components/ScrollToTop';
const Landing = lazy(() =>
  import("./pages/Landing").then((module) => ({
    default: module.Landing,
  }))
);
const Team = lazy(() =>
  import("./pages/Team").then((module) => ({ default: module.Team }))
);
const Contact = lazy(() =>
  import("./pages/Contact").then((module) => ({ default: module.Contact }))
);
const Framework = lazy(() =>
  import("./pages/Framework").then((module) => ({ default: module.Framework }))
);
const Privacy = lazy(() =>
  import("./pages/Privacy").then((module) => ({ default: module.Privacy }))
);
const LoadStatus = () => {
  return (
    <div className="py-4 text-center w-100">
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={"70px"}
        width={"70px"}
        color="#666666"
      />
    </div>
  );
};
function App() {
  return (
    <Suspense fallback={<LoadStatus />}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="" element={<Landing />} />
            <Route
              index
              path="/studens_parents_guardians"
              element={<StudentParents />}
            />
            <Route path="foundations" element={<Foundations />} />
            <Route path="year_college" element={<YearCollege />} />
            <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />
            <Route path="framework" element={<Framework />} />
            <Route path="privacypolicy" element={<Privacy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
