import iphone_img from "../assets/images/iphone-15.png";
import iphone_2 from "../assets/images/iphone_2.png";
import iphone_3 from "../assets/images/iphone_3.png";
import iphone_4 from "../assets/images/iphone_4.png";
import check_icon from "../assets/images/circle_check.svg";
import video_thumb from "../assets/images/video_thumb_1.png";
import curve_left from "../assets/images/arrow_curve_left.png";
import curve_right from "../assets/images/arrow_curve_right.png";
import manage_svg_com from "../assets/images/management-svgrepo-com.png";
import communicate_icon from "../assets/images/user_green.png";
import hand_shake from "../assets/images/hand_shake.png";
import student_icons from "../assets/images/doctor_icon.png";
import time_saving from "../assets/images/time_saving.png";
import inclusion from "../assets/images/inclusion.png";
import list_candidates from "../assets/images/list_candidates.png";
import list_candidate_1 from "../assets/images/list_candidates_1.png";
import college_logo from "../assets/images/mycollege_cost_logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper rounded-lg max-md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
      <div className={`player-wrapper rounded-lg md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height={220}
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
    </div>
  );
};
type IProps = {
  title: string;
  desc: string;
};
type ItemProps = {
  title: string;
  desc: string;
  src: string;
};
const ItemBox = ({ title, desc }: IProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <h6 className="text-[#2B574F] max-md:text-lg md:text-xl xl:text-2xl font-black flex">
        <img src={check_icon} alt="" />
        {title}
      </h6>
      <p className="max-md:text-sm md:text-base xl:text-lg font-light text-[#646464]">
        {desc}
      </p>
    </div>
  );
};
const ItemSrcBox = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold xl:text-2xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center">{desc}</p>
      </div>
    </div>
  );
};
const ItemSrcBoxMobile = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold text-xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center text-[14px]">{desc}</p>
      </div>
    </div>
  );
};
export const Foundations = () => {
  return (
    <>
      <div className="max-md:hidden grid gap-y-[40px] font-work_sans pb-[40px]">
        <section className="foundation_hero_bg w-100 md:h-[622px] flex items-center justify-center relative">
          <div className="flex justify-center p-8 space-y-2 hero_caption space-x-4">
            <div className="space-y-3">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={college_logo} alt="" />
                </div>
                <h6 className="text-xl font-extrabold text-white">
                  MyCollegeCosts
                </h6>
              </div>
              <h6 className="text-[#fff] w-[550px] text-center font-poppins font-normal">
                A tool to help students receive the best possible financial aid
                award
              </h6>
            </div>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
        <section className="xl:flex xl:justify-center xl:space-x-[75px] xl:space-x-8 md:px-3 font-opensans">
          <div className="flex justify-center items-center">
            <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[670px] min-h-[448px] space-y-3 text-[#646464]">
              <p className="text-[#3FC6AD] font-bold text-2xl">
                Do your students meet these requirements?
              </p>
              <ul className="text-xl font-light list-disc list-inside text-[#646464] space-y-3">
                <li>
                  Wants to enroll in a four-year college or university in the Fall
                  this year?
                </li>
                <li>Plan to enroll as a Full-Time Student.</li>
                <li>
                  Will complete and submit the Free Application for Federal
                  Student Aid (FAFSA).
                </li>
                <li>
                  Will be dependent on a parent or guardian for financial support.
                </li>
                <li>
                  Are open to sharing selected information from the FAFSA on the
                  MyCollegeCosts tool.
                </li>
                <li>Can complete a brief demographic form.</li>
              </ul>
              <h6 className="text-[#3FC6AD] font-bold text-xl">
                If so...Keep going! MyCollegeCosts may save you thousands of
                dollars! 
              </h6>
            </div>  
          </div>
          <div className="flex justify-center items-center md:mt-10">
            <VideoBox
              url="https://youtu.be/amrH4IIIlpM"
              className="md:w-[90vw] xl:w-[670px] md:h-[40vw] xl:h-[350px] rounded-lg"
            />
          </div>
        </section>
        <section className="flex justify-center xl:space-x-[75px] md:space-x-3 md:px-3">
          <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <h5 className="text-[#3FC6AD] text-2xl font-bold font-opensans">
              MyCollegeCosts allows the students you are helping...
            </h5>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3 text-xl">
              <li className="text-xl">
                Learn what the direct costs of attending the colleges listed on
                the FAFSA will be.
              </li>
              <li className="text-xl">
                See how much estimated financial aid your students need to pay
                for those direct costs.
              </li>
              <li className="text-xl">
                Understand how much of that financial aid is FREE and how much
                they will need to pay back or pay out-of-pocket.
              </li>
              <li className="text-xl">
                Review the expected GAP between the aid they are likely to be
                offered and how much they need.
              </li>
              <li className="text-xl">
                Engage in the important process of financial planning for
                college.
              </li>
              <li className="text-xl">
                Avoid insurmountable debt related to college enrollment.
              </li>
              <li className="text-xl">
                Receive this information without having to wait for an admission
                decision.
              </li>
              <li className="text-xl">
                Access recommended email and letter text to request the amount
                of aid they need from the colleges they are considering.
              </li>
              <li className="text-xl">
                Know that the college they want to attend is one they can afford
                to attend.
              </li>
            </ul>
          </div>
        </section>
        <section className="xl:flex xl:justify-center xl:space-x-[75px] xl:space-x-8 md:px-3 font-opensans">
          <div className="flex justify-center items-center">
            <p className="font-opensans xl:text-2xl font-light xl:w-[50vw] md:w-[90vw] text-center">
              Give your students more CONTROL over the financial aid process.
              Increase the probability that your students will be able to afford
              the college of their choice!
            </p>
          </div>
          <div className="flex justify-center items-center md:mt-5">
            <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
              <NavLink to={"/contact"}>
                <span>Contact Us</span>
              </NavLink>
            </button>
          </div>
        </section>
      </div>
      <div className="grid px-3 py-4 md:hidden gap-y-4 font-work_sans">
        <section className="flex flex-col justify-center space-y-3">
          <div className="flex justify-center items-center">
            <div className="border-shadow p-4 md:w-[90vw] xl:w-[670px] min-h-[448px] space-y-3 text-[#646464]">
              <p className="text-[#3FC6AD] font-bold text-lg">
                Do your students meet these requirements?
              </p>
              <ul className="text-md font-light list-disc list-inside text-[#646464] space-y-3">
                <li>
                  Wants to enroll in a four-year college or university in the Fall
                  this year?
                </li>
                <li>Plan to enroll as a Full-Time Student.</li>
                <li>
                  Will complete and submit the Free Application for Federal
                  Student Aid (FAFSA).
                </li>
                <li>
                  Will be dependent on a parent or guardian for financial support.
                </li>
                <li>
                  Are open to sharing selected information from the FAFSA on the
                  MyCollegeCosts tool.
                </li>
                <li>Can complete a brief demographic form.</li>
              </ul>
              <h6 className="text-[#3FC6AD] font-bold text-lg">
                If so...Keep going! MyCollegeCosts may save you thousands of
                dollars! 
              </h6>
            </div>  
          </div>
          <div className="flex items-center">
            <VideoBox
              url="https://youtu.be/amrH4IIIlpM"
              className="w-full rounded-lg"
            />
          </div>
        </section>
        <section className="flex flex-col space-y-4">
          <div className="border-shadow p-4 md:w-[90vw] xl:w-[1100px] md:min-h-[448px] space-y-5">
            <h5 className="text-[#3FC6AD] text-lg font-bold font-opensans">
              MyCollegeCosts allows the students you are helping...
            </h5>
            <ul className=" font-opensans font-light list-disc list-inside text-[#646464] space-y-3 text-md">
              <li className="text-md">
                Learn what the direct costs of attending the colleges listed on
                the FAFSA will be.
              </li>
              <li className="text-md">
                See how much estimated financial aid your students need to pay
                for those direct costs.
              </li>
              <li className="text-md">
                Understand how much of that financial aid is FREE and how much
                they will need to pay back or pay out-of-pocket.
              </li>
              <li className="text-md">
                Review the expected GAP between the aid they are likely to be
                offered and how much they need.
              </li>
              <li className="text-md">
                Engage in the important process of financial planning for
                college.
              </li>
              <li className="text-md">
                Avoid insurmountable debt related to college enrollment.
              </li>
              <li className="text-md">
                Receive this information without having to wait for an admission
                decision.
              </li>
              <li className="text-md">
                Access recommended email and letter text to request the amount
                of aid they need from the colleges they are considering.
              </li>
              <li className="text-md">
                Know that the college they want to attend is one they can afford
                to attend.
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="space-y-4">
            <div className="flex justify-center">
              <Link
                to="/contact"
                className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
